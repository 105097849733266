import {
  USER_SIGNED_IN,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_ERROR,
  UserActionTypes,
  UserState,
} from "./types";

export const initialState: UserState = {
  userId: null,
  isSignedIn: false,
  username: null,
  language: "en",
  name: {
    title: null,
    firstName: null,
    lastName: null,
  },
  favouriteCountries: [],
  remainingCredits: 0,
  services: [],
  userDetailsError: false,
  owningEntity: null,
};

export default function (
  state = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case USER_SIGNED_IN: {
      return {
        ...state,
        userId: action.payload,
        isSignedIn: true,
      };
    }
    case FETCH_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_USER_DETAILS_ERROR: {
      return {
        ...state,
        userDetailsError: true,
      };
    }
    default:
      return state;
  }
}
