import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink as RouterLink } from "react-router-dom";
import AppBar from "../../components/AppBar";
import Routes from "../Routes/Routes";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";
import AssessmentIcon from "@material-ui/icons/Assessment";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SecurityIcon from "@material-ui/icons/Security";
import { useGetFeatureFlag } from "../../api/features";
import { FeatureFlag } from "../../components/Features";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: theme.spacing(2),
    },
    drawer: {
      flexShrink: 0,
    },
    content: {
      flexGrow: 1,
    },
    routes: {
      color: theme.palette.grey[800],
    },
    iconNamePair: {
      display: "flex",
      alignItems: "center",
      "&>svg": {
        margin: theme.spacing(1, 2),
      },
    },
    footer: {
      height: "4rem",
    },
  })
);

const AppLayout = () => {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);

  const featureGenerateReports = useGetFeatureFlag(FeatureFlag.GenerateReports);

  const routes = [
    { title: "Home", to: "/", exact: true, icon: <HomeIcon /> },
    { title: "Screen", to: "/search", icon: <SearchIcon /> },
    { title: "ID&V", to: "/idv", icon: <SecurityIcon /> },
    { title: "Profiles", to: "/profiles", icon: <AccountCircleIcon /> },
    {
      title: "Monitoring",
      to: "/monitoring",
      icon: <NewReleasesIcon />,
    },
    { title: "Audits", to: "/audits", icon: <VerifiedUserIcon /> },
    { title: "Uploads", to: "/uploads", icon: <CloudUploadIcon /> },
    featureGenerateReports.featureFlag === "on"
      ? { title: "Reports", to: "/reports", icon: <AssessmentIcon /> }
      : null,
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {routes.map(
          (route) =>
            route && (
              <ListItem
                className={classes.routes}
                component={RouterLink}
                to={route.to}
                key={route.title}
                activeClassName="Mui-selected"
                exact={route.exact}
                onClick={handleDrawerToggle}
              >
                <ListItemText>
                  <div className={classes.iconNamePair}>
                    {route.icon}
                    {route.title}
                  </div>
                </ListItemText>
              </ListItem>
            )
        )}
      </List>
    </div>
  );
  const Footer = () => {
    return <div className={classes.footer}></div>;
  };
  return (
    <div className={classes.root}>
      <AppBar onToggle={handleDrawerToggle} />

      <Hidden smUp implementation="js">
        <Drawer
          className={classes.drawer}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="js">
        <Drawer className={classes.drawer} variant="permanent" open>
          {drawer}
        </Drawer>
      </Hidden>

      <main className={classes.content}>
        <Toolbar />
        <Routes />
        <Footer />
      </main>
    </div>
  );
};

export default AppLayout;
