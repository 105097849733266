import { ajax } from "rxjs/ajax";
import request from "./api/request";

const location = window.location;

export interface EpicDependencies {
  ajax: typeof ajax;
  localStorage: Storage;
  location: { href: string };
  request: typeof request;
}

const dependencies: EpicDependencies = {
  ajax,
  localStorage,
  location,
  request,
};

export default dependencies;
