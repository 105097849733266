import { RootState } from "../rootReducer";

export const getCanAccessIdv = (store: RootState) =>
  store.user.services.some((service) => service.serviceId == 3061);

export const getUserState = (store: RootState) => store.user;

export const getIsSignedIn = (store: RootState) =>
  getUserState(store).isSignedIn;

export const getFavouriteCountries = (store: RootState) =>
  getUserState(store).favouriteCountries;

export const getOwningEntity = (store: RootState) =>
  getUserState(store).owningEntity;
