import React from "react";
import { Switch, Route } from "react-router-dom";
import AppLayout from "./containers/AppLayout";
import { useSelector } from "react-redux";
import { getIsSignedIn } from "./store/user/selectors";
import SignInLoading from "./pages/SignInLoading";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/en-ca";
import "moment/locale/fr";
import useFreshworksWidget from "./hooks/useFreshworksWidget";

const locales: {
  [key: string]: string;
} = {
  "en-gb": "en-gb",
  "en-us": "en-us",
  "en-ca": "en-ca",
  fr: "fr",
};

const App: React.FC = () => {
  useFreshworksWidget(["CSUK", "CSIE"]);
  const isSignedIn = useSelector(getIsSignedIn);
  const userLocale = window.navigator.language.toLocaleLowerCase();
  moment.locale(
    locales[userLocale] || locales[userLocale.substring(0, 2)] || "en-ca"
  );

  if (!isSignedIn) {
    return <SignInLoading />;
  }

  return (
    <Switch>
      <Route component={AppLayout} />
    </Switch>
  );
};

export default App;
