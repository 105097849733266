import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Auth from "@creditsafe/upp-authentication";
import Hidden from "@material-ui/core/Hidden";
import { ReactComponent as Cslogo } from "../../assets/cslogo.svg";
import cslogoMini from "../../assets/csLogoMini.svg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.modal + 1,
    },
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    iconNamePair: {
      display: "flex",
      alignItems: "center",
      "&>span>svg": {
        margin: theme.spacing(0, 1),
      },
    },
    logo: {
      width: "25px",
      [theme.breakpoints.up("sm")]: {
        width: "198px",
      },
    },
    magicalColors: {
      fill: "#EE2E24",
    },
  })
);

interface AppBarProps {
  onToggle(): void;
}

const AppBar: React.FC<AppBarProps> = ({ onToggle }) => {
  const classes = useStyles();
  return (
    <MuiAppBar position="fixed" className={classes.appBar} color="default">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Link to="/" className={"brand " + classes.logo}>
          <Hidden xsDown>
            <Cslogo width="198px" height="35px" />
          </Hidden>
          <Hidden smUp>
            <img style={{ width: "25px" }} src={cslogoMini} alt="Creditsafe" />
          </Hidden>
        </Link>

        <Typography variant="h6" noWrap className={classes.title}>
          Protect
        </Typography>
        <Button
          color="inherit"
          onClick={() => Auth.signOut()}
          className={classes.iconNamePair}
        >
          <ExitToAppIcon />
          Sign Out
        </Button>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
