import { Epic } from "../types";
import { USER_SIGNED_IN, FetchUserDetailsSuccessPayload } from "./types";
import { of } from "rxjs";
import { map, switchMap, catchError, filter } from "rxjs/operators";
import { fetchUserDetailsSuccess, fetchUserDetailsError } from "./actions";
import { isOfType, ActionType } from "typesafe-actions";
import omit from "lodash/omit";
import config from "../../config";

export const fetchUserDetailsEpic: Epic<
  | ActionType<typeof fetchUserDetailsSuccess>
  | ActionType<typeof fetchUserDetailsError>
> = (action$, _$, { request, location }) =>
  action$.pipe(
    filter(isOfType(USER_SIGNED_IN)),
    switchMap(() =>
      request({
        method: "GET",
        url: `${config.COMPLIANCE_API_URL}/users/_me`,
      }).pipe(
        map((xhr) =>
          omit(
            {
              ...xhr.response,
              favouriteCountries: xhr.response.countries,
              remainingCredits: xhr.response.serviceDetails.remainingCredits,
            },
            "countries",
            "serviceDetails"
          )
        ),
        map((response) =>
          fetchUserDetailsSuccess(response as FetchUserDetailsSuccessPayload)
        ),
        catchError(({ response }) => {
          if (response.type === "ServiceLineUnavailable") {
            location.href = config.NOT_IN_SUBSCRIPTION_URL;
          }
          return of(fetchUserDetailsError());
        })
      )
    )
  );
