import axios, { AxiosResponse } from "axios";
import config from "../config";
import { ProblemDetails } from "./common/types";

const createUrl = (url: string): string =>
  !url.startsWith(config.COMPLIANCE_API_URL)
    ? `${config.COMPLIANCE_API_URL}${url}`
    : url;

const addAuthHeader = (
  headers?: Record<string, string>
): Record<string, string> => {
  if (!headers) {
    headers = {};
  }
  if (!headers.authorization) {
    headers.authorization = `Bearer ${localStorage.accessToken}`;
  }
  return headers;
};

// The type for TDataType in the methods below should be changed to unknown to force us to
// specify types, however, I'm putting it as any for now so that I don't have to update all
// of the preexisting methods that already utilised the methods below before I changed the
// below to be generic

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAsync = async <TDataType = any>(
  url: string,
  headers?: Record<string, string>,
  signal?: AbortSignal
) =>
  (await axios({
    method: "GET",
    url: createUrl(url),
    headers: addAuthHeader(headers),
    signal,
  })) as AxiosResponse<TDataType>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postAsync = async <TDataType = any>(
  url: string,
  data?: unknown,
  headers?: Record<string, string>
) => await sendAsync<TDataType>("POST", url, data, headers);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putAsync = async <TDataType = any>(
  url: string,
  data?: unknown,
  headers?: Record<string, string>
) => await sendAsync<TDataType>("PUT", url, data, headers);

export const patchAsync = async <TDataType = unknown>(
  url: string,
  data?: unknown,
  headers?: Record<string, string>
) => await sendAsync<TDataType>("PATCH", url, data, headers);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendAsync = async <TDataType = any>(
  method: "PUT" | "POST" | "PATCH",
  url: string,
  data?: unknown,
  headers?: Record<string, string>
) => {
  try {
    return (await axios({
      method,
      url: createUrl(url),
      data,
      headers: addAuthHeader(headers),
    })) as AxiosResponse<TDataType>;
  } catch (error) {
    throw (
      error.response?.data ??
      ({
        title: "Unknown API Error",
        type: "UnknownApiError",
        detail: `An unknown error occurred during an API call to the url ${url}`,
      } as ProblemDetails)
    );
  }
};
