class Duration {
  quantity: number;
  unit: TimeUnits;

  constructor(quantity: number, unit: TimeUnits | string) {
    this.quantity = 0;
    this.unit = TimeUnits.Milliseconds;
    this.setDuration(quantity, unit);
  }

  private setDuration(quantity: number, unit: TimeUnits | string) {
    this.quantity = quantity;
    let unitToUse: TimeUnits;
    if (typeof unit == "string") {
      if ((unit as TimeUnits) != undefined) {
        unitToUse = unit as TimeUnits;
      } else {
        throw `'${unit}' is not a recognised unit of time!`;
      }
    } else {
      unitToUse = unit;
    }
    this.unit = unitToUse;
  }

  static fromString(durationString: string) {
    const parts = durationString.split(" ");
    return new Duration(parseFloat(parts[0]), parts[1]);
  }

  to(targetUnit: TimeUnits) {
    switch (targetUnit) {
      case TimeUnits.Milliseconds:
        return this.toMilliseconds();
      default:
        throw "Unknown time unit!";
    }
  }

  toMilliseconds() {
    switch (this.unit) {
      case TimeUnits.Milliseconds:
        return this.quantity;
      case TimeUnits.Seconds:
        return this.quantity * 1000;
      case TimeUnits.Minutes:
        return this.quantity * 1000 * 60;
      case TimeUnits.Hours:
        return this.quantity * 1000 * 60 * 60;
      case TimeUnits.Days:
        return this.quantity * 1000 * 60 * 60 * 24;
      case TimeUnits.Weeks:
        return this.quantity * 1000 * 60 * 60 * 24 * 7;
      case TimeUnits.Months:
        return this.quantity * 1000 * 60 * 60 * 24 * (365 / 12);
      case TimeUnits.Years:
        return this.quantity * 1000 * 60 * 60 * 24 * 365;
    }
  }
}

export enum TimeUnits {
  Milliseconds = "milliseconds",
  Seconds = "seconds",
  Minutes = "minutes",
  Hours = "hours",
  Days = "days",
  Weeks = "weeks",
  Months = "months",
  Years = "years",
}

export default Duration;
