import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ReactComponent as Cslogo } from "../../assets/cslogo.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "90vh",
    },
    gridItem: {
      padding: theme.spacing(2),
    },
  })
);

const SignInLoading: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      className={classes.root}
    >
      <Grid item className={classes.gridItem}>
        <Cslogo width="198px" height="50px" />
      </Grid>

      <Grid item className={classes.gridItem}>
        <Typography variant="subtitle1"> Signing you in... </Typography>
      </Grid>
      <Grid item className={classes.gridItem}>
        <CircularProgress size={60} />
      </Grid>
    </Grid>
  );
};

export default SignInLoading;
