import {
  USER_SIGNED_IN,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_ERROR,
  FetchUserDetailsSuccessPayload,
} from "./types";
import { action } from "typesafe-actions";

export const userSignedIn = (payload: string) =>
  action(USER_SIGNED_IN, payload);

export const fetchUserDetailsSuccess = (
  payload: FetchUserDetailsSuccessPayload
) => action(FETCH_USER_DETAILS_SUCCESS, payload);

export const fetchUserDetailsError = () => action(FETCH_USER_DETAILS_ERROR);
