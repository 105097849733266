import React from "react";
import { makeStyles, createStyles, alpha } from "@material-ui/core";
import LoadingSpinner from "./LoadingSpinner";

const useStyles = makeStyles(() =>
  createStyles({
    overlay: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1000,
      background: alpha("#000", 0.2),
      overflowX: "hidden",
    },
  })
);

const LoadingSpinnerOverlay = () => {
  const classes = useStyles();
  return (
    <div className={classes.overlay}>
      <LoadingSpinner />
    </div>
  );
};

export default LoadingSpinnerOverlay;
