import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createBrowserHistory } from "history";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Auth, { AuthenticationProvider } from "@creditsafe/upp-authentication";
import configureStore from "./store/configureStore";
import { userSignedIn } from "./store/user/actions";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme";

Auth.configure({ appName: "compliance-app" });
const store = configureStore();
const history = createBrowserHistory();
const queryClient = new QueryClient();

const Root = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <AuthenticationProvider
          onSignin={(oidcUser) =>
            store.dispatch(userSignedIn(oidcUser.profile.sub))
          }
          history={history}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </ThemeProvider>
        </AuthenticationProvider>
      </Router>
    </QueryClientProvider>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
