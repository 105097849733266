import {
  FETCH_AUDITS,
  FETCH_AUDITS_SUCCESS,
  FETCH_AUDITS_ERROR,
  Audit,
} from "./types";
import { action } from "typesafe-actions";

export const fetchAudits = (queryParameters: {
  _page: number;
  _limit: number;
  _order: string;
  userId?: string;
  createdAt_lte?: string;
  createdAt_gte?: string;
  q?: string;
  type?: string;
  profileId?: string;
}) => action(FETCH_AUDITS, queryParameters);

export const fetchAuditsSuccess = (audits: Audit[], count: number) =>
  action(FETCH_AUDITS_SUCCESS, { count, audits });

export const fetchAuditsError = () => action(FETCH_AUDITS_ERROR);
