import { Normalized } from "../types";
import * as AuditsActions from "./actions";
import { ActionType } from "typesafe-actions";

interface ProfileAuditPayload {
  profileId: string;
  profileName: string;
}

interface InvestigationAuditPayload {
  investigationId: string;
  name: string;
}

export interface Audit {
  id: string;
  userId: string;
  createdAt: string;
  type: string;
  description: string;
  payload: ProfileAuditPayload | InvestigationAuditPayload;
}

export type AuditsState = Normalized<Audit, string>;

export const FETCH_AUDITS = "FETCH_AUDITS";
export const FETCH_AUDITS_SUCCESS = "FETCH_AUDITS_SUCCESS";
export const FETCH_AUDITS_ERROR = "FETCH_AUDITS_ERROR";

export type AuditActionTypes = ActionType<typeof AuditsActions>;
