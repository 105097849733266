import {
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
} from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

const defaultFonts = [
  "Open Sans",
  "Arial",
  "Helvetica Neue",
  "Helvetica",
  "sans-serif",
].join(",");

const headingFonts = [
  "Varela Round",
  "Arial",
  "Helvetica Neue",
  "Helvetica",
  "sans-serif",
].join(",");

const drawerWidth = 225;

const inputFontSize = "1.2rem";
const secondaryColor = "#329be2";

export const protectThemeOptions: ThemeOptions = {
  palette: {
    background: {
      default: "white",
    },
    primary: {
      main: "#ee2e24",
    },
    secondary: {
      main: secondaryColor,
    },
    error: {
      main: red[600],
      light: red[50],
      contrastText: "#ffffff",
    },
    success: {
      main: "#43a047",
    },
    warning: {
      main: "#fb8c00",
    },
    disabled: {
      main: "#d3d3d3",
    },
    action: {
      active: "#737b7d",
    },
    info: {
      main: "#5DA7DB",
    },
  },
  typography: {
    /* 
      `htmlFontSize` is the font size for the <html> element, browser default is 16px, (62.5% of 16px = 10px)  
    */
    htmlFontSize: 10,
    fontFamily: defaultFonts,
    h1: {
      fontFamily: headingFonts,
    },
    h2: {
      fontFamily: headingFonts,
    },
    h3: {
      fontFamily: headingFonts,
    },
    h4: {
      fontFamily: headingFonts,
    },
    h5: {
      fontFamily: headingFonts,
    },
    h6: {
      fontFamily: headingFonts,
    },
    button: {
      textTransform: "none",
    },
  },
  props: {
    MuiToolbar: {
      variant: "dense",
    },
    MuiTextField: {
      size: "small",
      color: "secondary",
    },
  },

  overrides: {
    MuiAppBar: {
      colorDefault: { backgroundColor: "#fafafa" },
    },
    MuiCard: {
      root: {
        borderTop: "#ee2e24 2px solid",
        borderRadius: "0 0 2px 2px",
        padding: "16px",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        "border-color": "lightGrey",
      },
    },
    MuiTextField: {
      root: {
        borderRadius: 4,
        backgroundColor: "white",

        "&.MuiOutlinedInput-root": {
          "&:hover fieldset": {
            borderColor: "#bdbdbd",
          },
          "&.Mui-focused fieldset": {
            borderColor: secondaryColor,
          },
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: inputFontSize,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: inputFontSize,
      },
    },
    MuiTab: {
      root: {
        backgroundColor: "#f5f5f5",
        "&$selected": {
          backgroundColor: "#eeeeee",
        },
      },
    },
    MuiDrawer: {
      root: {
        width: drawerWidth,
      },
      paper: {
        width: drawerWidth,
        backgroundColor: "#e1e1e1",
      },
    },
  },
};
const theme = createTheme(protectThemeOptions);

export default responsiveFontSizes(theme);
