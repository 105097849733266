import React from "react";

export const AsyncPageNotFound = React.lazy(
  () => import("../../pages/PageNotFound")
);

export const AsyncPageInMaintenance = React.lazy(
  () => import("../../pages/PageInMaintenance")
);

export const AsyncHome = React.lazy(() => import("../../pages/Home"));

export const AsyncSearch = React.lazy(() => import("../../pages/Search"));

export const AsyncIdv = React.lazy(() => import("../../pages/Idv/IdvPage"));

export const AsyncAudits = React.lazy(() => import("../../pages/Audits"));

export const AsyncProfiles = React.lazy(() => import("../../pages/Profiles"));

export const AsyncUploads = React.lazy(
  () => import("../../pages/Uploads/Uploads")
);

export const AsyncMonitoring = React.lazy(
  () => import("../../pages/Monitoring")
);

export const AsyncReports = React.lazy(
  () => import("../../pages/GenerateReports")
);
