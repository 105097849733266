import {
  Audit,
  AuditsState,
  AuditActionTypes,
  FETCH_AUDITS_SUCCESS,
  FETCH_AUDITS,
  FETCH_AUDITS_ERROR,
} from "./types";

export const initialState: AuditsState = {
  allIds: [],
  byId: {},
  isLoading: false,
  hasError: false,
};

export default function (
  state = initialState,
  action: AuditActionTypes
): AuditsState {
  switch (action.type) {
    case FETCH_AUDITS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_AUDITS_SUCCESS: {
      return {
        ...state,
        allIds: action.payload.audits.map((x) => x.id),
        byId: normalize(action.payload.audits),
        totalCount: action.payload.count,
        hasError: false,
        isLoading: false,
      };
    }
    case FETCH_AUDITS_ERROR: {
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}

const normalize = (audits: Audit[]): { [id: string]: Audit } =>
  audits.reduce((acc, x) => ({ ...acc, [x.id]: x }), {});
