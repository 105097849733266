import * as UserActions from "./actions";
import { ActionType } from "typesafe-actions";

export const USER_SIGNED_IN = "USER_SIGNED_IN";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_ERROR = "FETCH_USER_DETAILS_ERROR";

export interface UserState {
  userId: string | null;
  isSignedIn: boolean;
  username: string | null;
  language: string;
  name: {
    title: string | null;
    firstName: string | null;
    lastName: string | null;
  };
  favouriteCountries: string[];
  remainingCredits: number;
  services: ServiceDetails[];
  userDetailsError: boolean;
  owningEntity: string | null;
}

export interface FetchUserDetailsSuccessPayload {
  username: string;
  language: string;
  name: {
    title: string;
    firstName: string;
    lastName: string;
  };
  favouriteCountries: string[];
  remainingCredits: number;
  services: ServiceDetails[];
}

export interface ServiceDetails {
  serviceId: number;
  paid: number;
  used: number;
  name: string;
  pseudoId: string;
}

export type UserActionTypes = ActionType<typeof UserActions>;
