import React, { Suspense } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
  AsyncAudits,
  AsyncHome,
  AsyncIdv,
  AsyncMonitoring,
  AsyncPageInMaintenance,
  AsyncPageNotFound,
  AsyncProfiles,
  AsyncReports,
  AsyncSearch,
  AsyncUploads,
} from "./LoadableRoutes";
import { FeatureFlagType } from "../../hooks/type";
import { useGetFeatureFlag } from "../../api/features";
import { FeatureFlag } from "../../components/Features";

const renderReports = (
  featureFlag: FeatureFlagType,
  isError: boolean
): JSX.Element | null => {
  switch (featureFlag) {
    case "on":
      return <Route path="/reports" component={AsyncReports} key="/reports" />;
    case "maintenance":
      return (
        <Route
          path="/reports"
          component={AsyncPageInMaintenance}
          key="/reports"
        />
      );
    default:
      return isError ? null : <LoadingSpinner />;
  }
};

const Routes = () => {
  const { featureFlag, isError } = useGetFeatureFlag(
    FeatureFlag.GenerateReports
  );

  return (
    <>
      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <Route exact path="/" component={AsyncHome} key="/" />
          <Route path="/search" component={AsyncSearch} key="/search" />
          <Route path="/idv" component={AsyncIdv} key="/idv" />
          <Route path="/audits" component={AsyncAudits} key="/audits" />
          <Route path="/profiles" component={AsyncProfiles} key="/profiles" />
          <Route
            path="/monitoring"
            component={AsyncMonitoring}
            key="/monitoring"
          />
          <Route path="/uploads" component={AsyncUploads} key="/uploads" />
          <Route path="/encompass/cs-sso" key="/encompass/cs-sso">
            <Redirect to="/" />
          </Route>
          {renderReports(featureFlag, isError)}
          {/* Page Not Found needs to be the last Route component because it is the fallback component*/}
          <Route component={AsyncPageNotFound} key="not-found" />,
        </Switch>
      </Suspense>
    </>
  );
};

export default withRouter(Routes);
