import { combineReducers } from "redux";
import user, { initialState as initialUserState } from "./user/reducer";
import audits, { initialState as initialAuditsState } from "./audits/reducer";

const rootReducer = combineReducers({
  user,
  audits,
});

export type RootState = ReturnType<typeof rootReducer>;

export const initialState = {
  user: initialUserState,
  audits: initialAuditsState,
};

export default rootReducer;
