import { Epic } from "../types";
import { FETCH_AUDITS, Audit } from "./types";
import { of } from "rxjs";
import { map, switchMap, catchError, filter } from "rxjs/operators";
import { fetchAuditsSuccess, fetchAuditsError } from "./actions";
import { isOfType, ActionType } from "typesafe-actions";
import queryString from "query-string";
import config from "../../config";

export const fetchAuditsEpic: Epic<
  ActionType<typeof fetchAuditsSuccess> | ActionType<typeof fetchAuditsError>
> = (action$, _$, { request }) =>
  action$.pipe(
    filter(isOfType(FETCH_AUDITS)),
    switchMap((action) => {
      const queryParameters = queryString.stringify({
        ...action.payload,
        "payload.profileId": action.payload.profileId,
      });
      return request({
        method: "GET",
        url: `${config.COMPLIANCE_API_URL}/audits?${queryParameters}`,
      }).pipe(
        map(({ response, xhr }) => {
          const count = xhr.getResponseHeader("X-Total-Count") || "0";
          const audits: Audit[] = response;
          return fetchAuditsSuccess(audits, parseInt(count, 10));
        }),
        catchError(() => of(fetchAuditsError()))
      );
    })
  );
