import { useQuery } from "react-query";
import { FeatureFlag } from "../../components/Features";
import Duration, { TimeUnits } from "../../utils/duration";
import { getAsync } from "../protectApi";
import { GetAllFeatureFlagsResponse } from "./type";

const getAllFeatureFlags = async () => {
  const { data } = await getAsync<GetAllFeatureFlagsResponse>(`/features`);
  return { featureFlags: data };
};

const useGetAllFeatureFlags = () =>
  useQuery(["features", "all"], () => getAllFeatureFlags(), {
    cacheTime: new Duration(30, TimeUnits.Minutes).toMilliseconds(),
    staleTime: new Duration(30, TimeUnits.Minutes).toMilliseconds(),
  });

export const useGetFeatureFlag = (featureName: FeatureFlag) => {
  const query = useGetAllFeatureFlags();
  return {
    featureFlag: query.data?.featureFlags[featureName] ?? "off",
    isError: query.isError,
  };
};
