import { useEffect } from "react";

import { useSelector } from "react-redux";

import { getOwningEntity } from "../store/user/selectors";

const useFreshworksWidget = (entitiesToShowWidgetFor?: string[] | null) => {
  const owningEntity = useSelector(getOwningEntity) ?? "";

  useEffect(() => {
    if (
      !entitiesToShowWidgetFor ||
      entitiesToShowWidgetFor.includes(owningEntity)
    ) {
      const script1 = document.createElement("script");
      script1.src = "https://euc-widget.freshworks.com/widgets/7000000077.js";
      script1.type = "text/javascript";
      script1.async = true;
      script1.defer = true;
      const script2 = document.createElement("script");
      script2.innerText =
        "window.fwSettings={'widget_id':7000000077};!function(){if('function'!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() ";

      document.body.appendChild(script1);
      document.body.appendChild(script2);
    }
  }, [owningEntity]);
};

export default useFreshworksWidget;
